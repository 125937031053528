import axios from "axios";
import { Cookies } from "react-cookie";

const cookie = new Cookies();
const APIENDPOINT = process.env.REACT_APP_API_END_POINT;

const AxiosDefault = axios.create({
  baseURL: APIENDPOINT,
  timeout: 5000,
  headers: { "Content-Type": "application/json", Accept: "application/json" }
});

AxiosDefault.interceptors.request.use(
  async function (config) {
    try {
      const result = cookie.get("access_token");
      // console.log("result----------", result);
      if (result.length > 0) {
        config.headers.authorization = "Bearer " + result;
      }
    } catch (error) {
      console.log(error);
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

export default AxiosDefault;
