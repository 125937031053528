const EventEmitter = {
    events: {},
    dispatch(event, data) {
      if (!this.events[event]) return;
      this.events[event].forEach(callback => callback(data))
    },
    subscribe(event, callback) {
      if (!this.events[event]) this.events[event] = [];
      this.events[event].push(callback);
    },
    unsubscribe(event) {
      if (!this.events[event]) return;
      delete this.events[event];
    }
}
module.exports = { EventEmitter }